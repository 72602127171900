import React, {ReactNode} from 'react'
import {GatsbyImage, IGatsbyImageData} from "gatsby-plugin-image";

interface Props {
    children?: ReactNode
    image?: IGatsbyImageData
}
export const Header = ({children, image}: Props) => {
   return (
       <header className={"position-relative"}>
           {image &&
           <GatsbyImage
               alt={"artist impression"}
               image={image}
               className={"w-100"}
               style={{ maxHeight: 'calc(100vh - 4rem)' }}
           />
           }
           {children}
       </header>
   );
}