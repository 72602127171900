import React from "react"
import { graphql } from "gatsby"
import {Col, Container, Row} from "react-bootstrap";
import {ResponseForm} from "gatsby-theme-nieuwbouw/src/components/response/Form";
import {Header} from "../components/Header";
import {ChildImageSharp} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import DNA from "../components/DNA";
import Realisatie from "../components/Realisatie";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {IPage} from "../types";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.markdownRemark.frontmatter.title} pageDescription={data.markdownRemark.frontmatter.description}>
            <Header image={data.desktopImage.childImageSharp.gatsbyImageData} />
            <Container
                fluid={'lg'}
                className={'text-center text-md-left py-5'}
            >
                    <DNA />
                <Realisatie />
            </Container>
        </Page>
    );
}

interface PageQueryData {
    markdownRemark: IPage
    desktopImage: ChildImageSharp
    site: {
        siteMetadata: {
            objectcode: string
            afdelingscode: string
            referral: string
        }
    }
}

export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: {slug: {eq: "contact"}}) {
            ...Page
        }
        desktopImage: file(relativePath: {eq: "sfeer/hoofdbeeld locatie en contact.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    aspectRatio: 4
                    transformOptions: {cropFocus: ENTROPY}
                    layout: FULL_WIDTH
                )
            }
        }
        site {
            siteMetadata {
                objectcode
                afdelingscode
                referral
            }
        }
    }
`

