import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";


export default () => {
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: {eq: "logos/realisatie.png"}) {
                childImageSharp {
                    gatsbyImageData(height: 65, quality: 90, layout: FIXED)
                }
            }
        }
    `)

    return <>
        <div className="pb-3">
            <GatsbyImage
                image={data.logo.childImageSharp.gatsbyImageData}
                alt="Logo De Realisatie"
                className="img-fluid mx-auto" />
          <Addres />
        </div>
    </>
}

export const Addres = () => {
    return (
        <address className="small">
            <a href="phone:0858771293" className={'text-dark'}>085 877 1293</a><br />
            <a href="mailto:info@de-realisatie.nl" className={'text-dark'}>info@de-realisatie.nl</a><br />
            <a href="https://www.de-realisatie.nl" className={'text-dark'} target="_blank" rel="noopener noreferrer">www.de-realisatie.nl</a><br />
        </address>
    )
}
